@import "minima";

body {
	font-family: "Source Code Pro", monospace;
}
.footer-heading {
	visibility: hidden;
	height: 0px;
	margin-bottom: 0px;
}

.site-header {
	border-top: 5px solid #ffffff;
	border-bottom: 1px solid $grey-color-light;
	min-height: $spacing-unit * 1.865;
	line-height: $base-line-height * $base-font-size * 2.25;

	// Positioning context for the mobile navigation icon
	position: relative;
}

.footer-col-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footnotes {
	font-size: smaller;
}

hr {
	border-bottom: 1px solid $grey-color-light;
}
